import Vue from 'vue'
// import Store from './Store'
import Vuex from 'vuex'
import { postFrameMessage } from '@/utils/iframeMessage'
import automation from './modules/automation'

// Vue.use(Store, 'store')
Vue.use(Vuex)

// FIXME 手动模拟一个状态管理对象 方便以后项目拓展改回vuex
const state = {
  botIcon: 'https://img.willdesk.com/static/default_robot.png?x-oss-process=image/resize,w_64,h_64,m_lfit', /* 机器人头像 */
  // 网站总开关
  siteState: true,
  // 当前显示页面 可以理解为路由
  currentPage: '',
  // 用户是否登录
  login: false,
  // 盒子填充 移动端16px pc端26px
  boxPadding: '',
  // 是否为移动端 默认为false
  isMobile: false,
  // 用户信息
  userInfo: null,
  // token
  token: '',
  // 店铺 为后期多店铺做准备
  shop: '',
  // 坐席信息
  customerServiceInfo: {},
  // 店铺设置信息
  shopSetting: {},
  // 主题颜色
  theme: [],
  // 当前文章id
  articleId: null,
  // 距离上班还有多少分钟 单位分钟 0表示处于上班时间
  workTime: 0,
  // 翻译语言对象
  language: {},
  // 翻译对象状态 true 不为空/false 空
  languageState: false,
  /* apps显示字体内容 */
  appsFont: {},
  themeColor: '#2C23E5',
  currOrderInfo:{},
  allCategoryArticle: [],
  serviceList: []
}

const getters = {
  TextSetting: state => JSON.parse(state.shopSetting.liveChat['0'])['text-settings'],
  socketLinkStatus: state => sessionStorage.getItem('socketLinkStatus') || 0,
  launchChatMessage: state => state.launchChatMessage,
  appsFont: state => state.appsFont,
  themeColor: state => state.themeColor,
  shopSetting: state => state.shopSetting, // 获取店铺设置
  getCurrOrderInfo: state => state.currOrderInfo, // 当前订单信息
  getAllCategoryArticle: (state) => state.allCategoryArticle,
  serviceList: state => state.serviceList
}

const mutations = {
  setServiceList (state, serviceList) {
    state.serviceList = serviceList
  },
  setThemeColor (state, color) {
    state.themeColor = color
  },
  setPage (state, val) {
    state.currentPage = val
    postFrameMessage({
      type: 'currentPage',
      page: val
    }, '*')
  },
  setAppsFont (state, val) {
    let fontFamily = ''
    switch (val) {
      case 'Inter':
      case 'Auton':
      case 'Kanit':
      case 'Nunito':
      case 'Poppins':
      case 'Roboto':
        fontFamily = val
        state.appsFont = {
          fontFamily: fontFamily + '!important'
        }
        break
      default:
        fontFamily = 'Inter'
        state.appsFont = {
          fontFamily: 'Inter!important'
        }
        break
    }
  },
  setPadding (state, val) {
    state.boxPadding = val
  },
  setSiteState (state, val) {
    state.siteState = val
  },
  setUserInfo (state, val) {
    state.userInfo = val
  },
  setLogin (state, val) {
    state.login = val
  },
  setToken (state, val) {
    state.token = val
  },
  setShop (state, val) {
    state.shop = val
  },
  // 设置是否为移动端
  setIsMobile (state, val) {
    state.isMobile = val
  },
  // 增加坐席信息
  addCustomerServiceInfo (state, val) {
    // state.customerServiceInfo.push(val);
    state.customerServiceInfo[val.id] = val
  },
  setShopSetting (state, val) {
    state.shopSetting = val
  },
  setTheme (state, val) {
    state.theme = val
  },
  setArticleId (state, val) {
    state.articleId = val
  },
  setWorkTime (state, val) {
    state.workTime = val
  },
  setLanguage (state, val) {
    state.language = val
  },
  setLanguageState (state, val) {
    state.languageState = val
  },
  setCurrOrderInfo (state, val) {
    state.currOrderInfo = val
  },
  setAllCategoryArticle: (state, list) => {
    state.allCategoryArticle = list
  },
}

// 通常跟api接口打交道 异步操作
const actions = {
  setPage (store, val) {
    // commit调用mutations里面的方法
    this.commit('setPage', val)
  },
  setSiteState (store, val) {
    this.commit('setSiteState', val)
  },
  setLogin (store, val) {
    this.commit('setLogin', val)
  },
  setUserInfo (store, val) {
    this.commit('setUserInfo', val)
  },
  setToken (store, val) {
    this.commit('setToken', val)
  },
  setShop (store, val) {
    this.commit('setShop', val)
  },
  // 增加坐席信息
  addCustomerServiceInfo (state, val) {
    this.commit('addCustomerServiceInfo', val)
  },
  setShopSetting (store, val) {
    this.commit('setShopSetting', val)
  },
  setTheme (store, val) {
    this.commit('setTheme', val)
  },
  setWorkTime (store, val) {
    this.commit('setWorkTime', val)
  },
  setLanguage (state, val) {
    this.commit('setLanguage', val)
  },
  setLanguageState (state, val) {
    this.commit('setLanguageState', val)
  }
}

const store = new Vuex.Store({
  modules: {
    automation
  },
  state,
  getters,
  mutations,
  actions
})

export default store
