import request from '@/network/request'

// 翻译文案接口
export function postTranslateText (data) {
  return request({
    url: 'v1/translate/transText',
    method: 'post',
    data
  })
}
