<template>
  <div class="article_comment" :class="$store.state.isMobile?'article_comment_mobile':''">
    <div class="comment_title">{{commonTitle}}</div>
    <!-- web端按钮 -->
    <div class="comment_btns" v-if="!$store.state.isMobile">
      <div class="comment_yes" :class="commentType !=='yes' ?'':'theme-comment-yes-color'" @click="clickComment('yes')">
        <svg-icon v-if="commentType !== 'yes'" svg-name="comment-yes" width="18px" height="20px"></svg-icon>
        <svg-icon class="theme-link-text-color" v-else-if="commentType == 'yes'" svg-name="comment-yes-active" width="18px" height="20px"></svg-icon>
        <!-- <span :class="commentType=='yes'?'theme-link-text-color':''">YES</span> -->
      </div>
      <div class="comment_yes" :class="commentType !=='no' ?'':'theme-comment-yes-color'" @click="clickComment('no')">
        <svg-icon class="comment_no_icon" v-if="commentType !== 'no'" svg-name="comment-yes" width="18px" height="20px"></svg-icon>
        <svg-icon class="comment_no_icon theme-link-text-color" v-else-if="commentType == 'no'" svg-name="comment-yes-active" width="18px" height="20px"></svg-icon>
        <!-- <span :class="commentType=='no'?'theme-link-text-color':''">NO</span> -->
      </div>
    </div>
    <!-- 移动端按钮 -->
    <div class="comment_btns" v-else>
      <div class="comment_yes" @click="clickComment('yes')">
        <svg-icon :svg-name="commentType == 'yes' ? 'comment-yes-active':'comment-yes'" width="15px" height="16px"></svg-icon>
      </div>
      <div class="comment_yes" @click="clickComment('no')">
        <svg-icon class="comment_no_icon" :svg-name="commentType == 'yes' ? 'comment-yes-active':'comment-yes'" width="15px" height="16px"></svg-icon>
      </div>
    </div>
    <div class="helpcenter" v-if="commentType === 'no'">
      <div class="helpcenter_title helpcenter_title_bad">{{noContentText}}</div>
      <!-- <button>Chat with us</button> -->
      <div class="btn_container">
        <adaptive-button v-if="chatState" class="adaptive_button" @btnClick="btnClick" click-methods="setPage" click-arg="login" btnHeight="36px" :btnText="chatButtonText" fontSize="14px" iconName="chat-icon"></adaptive-button>
        <adaptive-button v-if="leaveMessageState" class="adaptive_button email_btn" @btnClick="btnClick" click-methods="setPage" click-arg="leave-message" btnTheme="white" btnHeight="36px" :btnText="emailButtonText" fontSize="14px" iconName="email"></adaptive-button>
      </div>
      <div class="helpcenter_enter theme-link-text-color" @click="$store.commit('setPage','SearchPage')" v-if="$store.state.currentPage !== 'search-page'">{{visitContent}}</div>
    </div>
    <div v-else-if="commentType === 'yes'" class="helpcenter_title">{{thankContent}}</div>
  </div>
</template>

<script>
import AdaptiveButton from '@/components/contents/AdaptiveButton'

export default {
  name: 'ArticleComment',
  components: { AdaptiveButton },
  props: {
    // 文章id
    articleId: {
      type: Number,
      require: true
    }
  },
  data () {
    return {
      // 评论的类型
      commentType: null,
      chatState: Boolean(Number(this.$store.state.shopSetting.WidgetStatus[0].EnableLiveChat)),
      leaveMessageState: Boolean(Number(this.$store.state.shopSetting.WidgetStatus[0].DisplayContentForm)),
      // title: this.$store.state.shopSelfText.helpCenter.feedbackDescription, // 'Is it helpful for you?',
      noContent: 'We are here to help',
      chatBtnText: 'Chat with us',
      emailBtnText: 'Email us',
      visitText: 'Visit the help center>',
      thankText: 'Thank you for your feedback.'
    }
  },
  computed: {
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    },
    helpCenter () {
      return this.textSetting.helpCenter
    },
    commonTitle () {
      // return this.helpCenter.feedbackDescription || this.$store.state.language.helpCenter[1] || 'Is it helpful for you?'
      return this.textSetting.helpCenter
    },
    noContentText () {
      return this.$store.state.languageState ? this.$store.state.language.helpCenter[2] : this.noContent
    },
    chatButtonText () {
      return this.$store.state.login ? this.$store.state.shopSelfText.resume : this.$store.state.shopSelfText.chat
    },
    emailButtonText () {
      return this.emailBtnText
    },
    visitContent () {
      return this.$store.state.languageState ? this.$store.state.language.helpCenter[6] : this.visitText
    },
    thankContent () {
      return this.$store.state.languageState ? this.$store.state.language.helpCenter[7] : this.thankText
    }
  },
  created () {},
  methods: {
    // async getLocation () {
    //   this.location = await getUserLocation('App调用').catch(e => { this.location = '' })
    // },
    // 点击评论按钮的回调
    clickComment (type) {
      if (this.commentType) return

      this.commentType = type

      // this.$emit('comment')
    },
    // 按钮点击事件
    /**
       * @param string methods 要调用的方法名称
       * @param arg 参数
       */
    btnClick (methods, arg) {
      // 调用方法 传入当前this指向 否则this是指向$options的
      // this.$options.methods[methods](arg, this);
      this[methods](arg)
    },
    // 跳转页面方法
    setPage (page) {
      const shopSetting = this.$store.state.shopSetting
      if (page === 'login' && !this.$store.state.login) {
        if (shopSetting.VisitorCollection[0].DataCollection === '0' || (shopSetting.VisitorCollection[0].DataCollection === '1' && shopSetting.VisitorCollection[0].Email === '0' && shopSetting.VisitorCollection[0].Name === '0')) {
          // 如果是跳转登录页 则检测是否开启收集信息设置 提升体验
        } else {
          this.$store.commit('setPage', page)
        }
      } else {
        this.$store.commit('setPage', page)
      }
    }
  }
}
</script>

<style scoped>
  .article_comment {
  }

  .comment_title {
    font-size: 14px;
    font-weight: 600;
    color: #727272;
    line-height: 20px;
    margin-bottom: 16px;
    text-align: center;
  }

  .comment_yes span {
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px;
  }

  .comment_yes {
    display: flex;
    color: #727272;
    align-items: center;
    margin: 0 23px;
    cursor: pointer;
  }

  /* .comment_yes:hover {
  color: #3B59FE;
} */

  .comment_btns {
    display: flex;
    justify-content: center;
  }

  .comment_no_icon {
    transform: rotateX(180deg);
  }

  .helpcenter {
    text-align: center;
  }

  .helpcenter_title {
    text-align: center;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #727272;
    line-height: 20px;
  }

  .helpcenter_title_bad {
    margin-bottom: 20px;
  }

  .helpcenter_enter {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    cursor: pointer;
  }

  .title,
  .content {
    user-select: text;
  }

  .adaptive_button {
    width: 300px;
    margin: 0px auto;
  }

  .adaptive_button /deep/ button {
    font-weight: 600;
  }

  .btn_container {
    text-align: center;
    margin: 9px 0;
  }

  .email_btn {
    margin-top: 8px;
  }

  .article_comment_mobile {}

  .article_comment_mobile .comment_title {
    margin-bottom: 16px;
    color: #292929;
    font-size: 12px;
  }

  .article_comment_mobile .comment_yes span,
  .article_comment_mobile .helpcenter_title {
    font-size: 12px;
  }

  .article_comment_mobile .comment_yes:hover {
    color: unset;
  }
</style>
