<template>
  <div class="leave-message">
    <nav-bar back-page="home">
      <template #left>
        <svg-icon svg-name="left-arrow" width="20px" height="20px"></svg-icon>
      </template>
      <template #center><span class="leave-message-title">{{navTitle}} </span></template>
    </nav-bar>
    <!-- 滚动容器 -->
    <page-scroll style="padding:16px;">
      <message-form/>
    </page-scroll>
  </div>
</template>

<script>
import NavBar from 'components/contents/NavBar'
import PageScroll from 'components/contents/PageScroll'
import MessageForm from './children/MessageForm'
export default {
  name: 'LeaveMessage',
  components: {
    NavBar,
    PageScroll,
    MessageForm
  },
  data () {
    return {
      title: 'Email us'
      // this.textSetting.email_us_button // 'Email us'
    }
  },
  computed: {
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    },
    iconWidth () {
      return this.$store.state.isMobile ? '10px' : '20px'
    },
    navTitle () {
      return this.$store.state.languageState ? this.$store.state.language.leaveMessage[0] : this.title
    }
  }
}
</script>

<style>
/* .leave-message{
  background: #fff;
} */
.page-scroll {
  height: calc(100vh - 74px) !important;
  top: 74px !important;
}

.leave-message-title {
  font-size: 16px;
}
</style>
